import React from "react";
import ThankYouPage from "../../components/thank-you/thank-you";

export default ({location}) => {

    return (<ThankYouPage location={location}>
            <h1>Thank you!</h1>

            <p>We appreciate you reaching out to us!<br/>Someone from our Sales team will get back to you shortly</p>
            <p>Wishing you an amazing day further!</p>
        </ThankYouPage>
    );
}